import TermsOfUse from ".";
import { Link } from "react-router-dom";
export default function Terms() {
  return (
    <>
      <div className="mb-2">
        <p>
          The following Terms of Service Agreement is a legally binding
          agreement that shall govern the relationship with our users
          (individual or entity), and RateHogs. By accessing the website at{" "}
          <Link to="#">www.ratehogs.com</Link> you are agreeing to be bound by
          these terms of service, all applicable laws and regulations, and agree
          that you are responsible for compliance with any applicable local
          laws.The materials contained in this website are protected by
          applicable copyright and trademark laws. By clicking on the box ‘I
          Agree’ you are deemed to have read and understood the terms and
          conditions of this End-User License agreement (EULA) and as such you
          are providing your acceptance of terms and conditions of this
          agreement. This agreement is enforceable against you and any legal
          entity that has utilised the website. If you do not agree to the terms
          of this agreement, you are prohibited from using or accessing this
          site.
        </p>
      </div>
      <section className="mb-2">
        <span style={{ fontWeight: "bold" }}>Use License</span>
        <p>
          Permission is granted to temporarily download one copy of the
          materials (information or software) on RateHogs' website for personal,
          non-commercial transitory viewing only. This is the grant of a
          license, not a transfer of title, and under this license you may not:
          modify or copy the materials; use the materials for any commercial
          purpose, or for any public display (commercial or non-commercial);
          attempt to decompile or reverse engineer any software contained on
          RateHogs' website; remove any copyright or other proprietary notations
          from the materials;or transfer the materials to another person or
          "mirror" the materials on any other server. This license shall
          automatically terminate if you violate any of these restrictions and
          may be terminated by RateHogs at any time. Upon terminating your
          viewing of these materials or upon the termination of this license,
          you must destroy any downloaded materials in your possession whether
          in electronic or printed format.
        </p>
      </section>
      <section>
        <span className="mb-2" style={{ fontWeight: "bold" }}>
          Disclaimer{" "}
        </span>
        <p>
          The materials on RateHogs website are provided on an 'as is' basis and
          you accept it with all faults(if any). RateHogs disclaims all
          warranties, expressed or implied, and hereby disclaims and negates all
          other warranties including, without limitation, implied warranties or
          conditions of merchantability, fitness for a particular purpose, or
          non-infringement of intellectual property or other violation of
          rights.
        </p>
        <p>
          Further, RateHogs does not warrant or make any representations
          concerning the accuracy, likely results, or reliability of the use of
          the materials on its website or otherwise relating to such materials
          or on any sites linked to this site; and as such, there should be no
          reason for you to bring any grievance against RateHogs. However, if
          you do file a lawsuit, claim, threat, or demand against RateHogs and
          you don't win the case in a significant way, you'll be responsible for
          covering all of the defendant's legal expenses. Likewise, you agree to
          cover RateHog's legal expenses if it needs to file a lawsuit to
          enforce this EULA.
        </p>
      </section>
      <section>
        <span className="mb-2" style={{ fontWeight: "bold" }}>
          Limitations
        </span>
        <p>
          In no event shall RateHogs and its employees, directors,licensors or
          its suppliers be liable for any direct, indirect, special, incidental,
          consequential or exemplary damages (including, without limitation,
          damages for loss of data or profit, or due to business interruption)
          arising out of the use or inability to use the materials on RateHogs's
          website, even if RateHogs or a RateHogs’ authorized representative has
          been notified orally or in writing of the possibility of such damage.
          Because some jurisdictions do not allow limitations on implied
          warranties, or limitations of liability for consequential or
          incidental damages, these limitations may not apply to you.
        </p>
      </section>
      <section>
        <span className="mb-2" style={{ fontWeight: "bold" }}>
          Privacy
        </span>
        <p>
          Personal Information- Some time after the initial access to the site,
          you may be asked to provide certain information that will be used to
          personalise certain services, monitor communication for quality
          improvement, and improve the services that we provide. For example,
          you may be asked to submit your name and email as a way to show
          interest in a product, which ratehogs could then provide this
          information to banking institutions in order to provide the best
          possible service to our users. All personal and non-personal
          information collected by RateHogs is safeguarded by storing it in a
          database of which only a limited number of persons have access to. The
          information will not be shared or given without your consent as
          described in our <TermsOfUse body={"privacy policy"} />.
        </p>
      </section>
      <section>
        <span className="mb-2" style={{ fontWeight: "bold" }}>
          Accuracy of Materials
        </span>
        <p>
          The materials appearing on RateHogs's website could include technical,
          typographical, or photographic errors. RateHogs does not warrant that
          any of the materials on its website are accurate, complete or current.
          RateHogs may make changes to the materials contained on its website at
          any time without notice. However RateHogs does not make any commitment
          to update the materials.
        </p>
      </section>
      <section>
        <span className="mb-2" style={{ fontWeight: "bold" }}>
          Third Party Links
        </span>
        <p>
          RateHogs has not reviewed all of the sites linked to its website and
          is not responsible for the contents of any such linked site. RateHogs
          has no control over, and assumes no responsibility for such websites
          or online services. The inclusion of any link does not imply
          endorsement by RateHogs of the site. Use of any such linked website is
          at the user's own risk.
        </p>
      </section>
      <section>
        <span className="mb-2" style={{ fontWeight: "bold" }}>
          Amendments and Changes to Agreement
        </span>
        <p>
          RateHogs may revise these terms of service for its website at any time
          without notice. For example to
          <ul>
            <li>reflect changes to our service or how we do business</li>
            <li>to prevent abuse or harm, or</li>
            <li>
              for legal, regulatory, or security reasons. By using this website
              you are agreeing to be bound by the then current version of these
              terms of service.
            </li>
          </ul>
        </p>
      </section>
      <section>
        <span className="mb-2" style={{ fontWeight: "bold" }}>
          Governing Law
        </span>
        <p>
          These terms and conditions are governed by and construed in accordance
          with the laws of Jamaica and you irrevocably submit to the exclusive
          jurisdiction of the courts in that State or location. The parties
          agree that any claims or suit on any ground whatsoever relating to
          this EULA, to select a judicial district in Jamaica, as the sole place
          for the hearing of such claims or suits.
        </p>
      </section>
      <section>
        <span className="mb-2" style={{ fontWeight: "bold" }}>
          Prohibited Uses
        </span>
        <p>
          You may not:
          <ul>
            <li>
              Modify or create any derivative works of the website without
              RateHogs’ express written consent
            </li>
            <li>
              Use the website in any way that violates this EULA or any law
            </li>
            <li>
              Remove or alter any trademark, logo, copyright or other
              proprietary notices or symbols on the website.
            </li>
          </ul>
        </p>
      </section>
      <section>
        <span className="mb-2" style={{ fontWeight: "bold" }}>
          Contact Us
        </span>
        <p>For inquiries please contact us by emailing: support@ratehogs.com</p>
      </section>
    </>
  );
}
