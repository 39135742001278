import React from "react";
import { Link } from "react-router-dom";
import TermsOfUse from "../components/Legal";

//images
import baner1 from "./../assets/images/home-banner/img5.png";
import bgimage from "./../assets/images/background/bg1.jpg";
//import logowhite from './../assets/images/logo-white.png';
import rateHogsLogo from "./../assets/images/rateHogs2.png";
//import flags from './../assets/images/footer/world-map-with-flags1.png';
import baseUrl from "../config";
function Footer() {
 /*  const baseUrl =
    window.location.hostname !== "localhost"
      ? "https://app.ratehogs.com"
      : "http://localhost:3001"; */
  return (
    <>
      <footer className="site-footer style-1" id="footer">
        <img className="bg-shape1" src={baner1} alt="" />
        <div
          className="footer-top background-luminosity"
          style={{ backgroundImage: "url(" + bgimage + ")" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-12 col-md-12">
                <div className="widget widget_about">
                  <div className="footer-logo logo-white">
                    <Link to={"/"}>
                      <img src={rateHogsLogo} alt="" />
                    </Link>
                  </div>
                  <p>
                    Disclaimer: Financial institutions may make updates to their
                    products from time to time. We do our best to identify and
                    update the information in a timely manner to give you
                    accurate comparisons. We are not a license financial advisor
                    therefore we do not provide investment advice.
                  </p>
                  <div className="dz-social-icon transparent space-10">
                    <ul>
                      {/* <li><a target="_blank" className="fab fa-facebook-f" href="https://www.facebook.com/"></a></li>{" "} */}
                      <li>
                        <a
                          target="_blank"
                          href="https://www.instagram.com/"
                          rel="norefferer"
                          className="fab fa-instagram"
                        ></a>
                      </li>{" "}
                      <li>
                        <a
                          target="_blank"
                          className="fab fa-twitter"
                          href="https://twitter.com/"
                          rel="noreferrer"
                        ></a>
                      </li>{" "}
                      {/* <li><a target="_blank" className="fab fa-youtube" href="https://youtube.com/"></a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2  col-lg-3 col-md-3 col-sm-3">
                <div className="widget widget_services">
                  <h4 className="widget-title">Products</h4>
                  <ul>
                    <li>
                      <a target="_self" href={`${baseUrl}/credit-cards`}>
                        Credit Cards
                      </a>
                    </li>
                    <li>
                      <a target="_self" href={`${baseUrl}/fx-rates`}>
                        Foreign Exchange
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3">
                <div className="widget widget_services">
                  <h4 className="widget-title">Legal</h4>
                  <ul>
                    <li>
                      <TermsOfUse body={"terms"} />
                    </li>
                    <li>
                      <Link to={"/privacy"}>Privacy Policy</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-md-3 col-sm-3">
                <div className="widget widget_services">
                  <h4 className="widget-title">Other Links</h4>
                  <ul>
                    <li>
                      <Link to={"/about-us"}>About Us</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xl-2 col-lg-4 col-sm-12">
                <div className="widget widget_locations">
                  <h4 className="widget-title">Contact</h4>
                  <div className="clearfix">
                    <h6 className="title">Email</h6>
                    <p>support@ratehogs.com</p>
                    {/* <img src={flags} alt="" /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom text-center">
          <div className="container">
            {/* <span className="copyright-text">Copyright © 2023 <a href="https://dexignzone.com/" target="_blank">DexignZone</a>. All rights reserved.</span> */}
            <span className="copyright-text">
              Copyright © {new Date().getFullYear()} RateHogs. All rights
              reserved.
            </span>
          </div>
        </div>
      </footer>
    </>
  );
}
export default Footer;
