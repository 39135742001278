import PageLayout from "../../layouts/PageLayout";

const PrivacyPolicy = () => {
  const Tr = ({ children }) => (
    <div style={{ minHeight: 45 }} className="d-flex align-items-stretch">
      {children}
    </div>
  );
  const Tc = ({ children }) => (
    <div style={{ border: "1px solid #eeee" }} className={"w-50 p-1"}>
      {children}
    </div>
  );
  return (
    <div className="page-content">
      <PageLayout pageTitle="Privacy Policy" />
      <div className="container content-inner">
        <p>
          RateHogs respects your privacy and is committed to protecting and
          being responsible with the personal data you provide to us. This
          privacy policy describes how RateHogs (&quot;we&quot; and
          &quot;us&quot;) collects and processes personal data of individuals
          who engage our services (referred to as &quot;you&quot; in this
          privacy policy) and your rights in relation to your personal data.
        </p>
        <p>
          This privacy policy outlines RateHogs’ practices regarding the
          collection, storage, use and disclosure of personal data.
          Additionally, this policy includes information about your rights
          concerning the personal data you provide to us.
        </p>
        <ol>
          <li style={{ listStyleType: "number" }}>
            <h5>Data Controller contact details</h5>
            <p>
              RateHogs is the data controller for the personal data you provide
              to us, and we hold ultimate responsibility for the personal data
              in our possession. If you have any concerns or queries about this
              privacy policy or our data protection practices, please get in
              touch with us using the details below: <br />
              36 Drumblair Crescent, Kingston 8 <br />
              support@ratehogs.com <br />
              <u>Data Protection Officer</u>
              <br />
              Stephen Scale (stephen.scale@solutechinnovation.com)
            </p>
          </li>
          <li style={{ listStyleType: "number" }}>
            <h5>Types of personal data we collect and process</h5>
            <p>
              The personal data that RateHogs processes include but is not
              limited to the following:
            </p>
            <ul style={{ marginLeft: 25, marginBottom: 15 }}>
              <li style={{ listStyleType: "disc" }}>
                <b>Personal information</b> - name, phone number, email address,
                job title.
              </li>
              <li style={{ listStyleType: "disc" }}>
                <b>Marketing and communications data</b> - social media
                accounts, device and browser information and queries or feedback
                you provide.
              </li>
            </ul>
          </li>
          <li style={{ listStyleType: "number" }}>
            <h5>How we collect personal data</h5>
            <p>
              RateHogs uses different methods to collect personal data,
              including the following:
            </p>
            <ul style={{ marginLeft: 25, marginBottom: 15 }}>
              <li style={{ listStyleType: "disc" }}>
                Directly from you, such as through completed applications, via
                email or phone.
              </li>
              <li style={{ listStyleType: "disc" }}>
                Through the usage of our platforms, such as our apps and
                websites.
              </li>
            </ul>
          </li>
          <li style={{ listStyleType: "number" }}>
            <h5>Purposes for using personal data</h5>
            <p>
              RateHogs processes personal data for various purposes to provide
              products and services as requested by you, comply with legal
              obligations, and continue developing and growing our business.
              These purposes may include, but are not limited to, the following:
            </p>
            <div
              className="w-100 mb-2"
              style={{ display: "table", border: "1px solid #eeee" }}
            >
              <Tr>
                <Tc>
                  <div className="text-black text-center">
                    Purposes for using personal data
                  </div>
                </Tc>
                <Tc>
                  <div className="text-center text-black">Lawful Basis</div>
                </Tc>
              </Tr>
              <Tr>
                <Tc>To provide the necesssary services and products to you.</Tc>
                <Tc>Performance of a contract with you.</Tc>
              </Tr>
              <Tr>
                <Tc>
                  To manage our relationship with you, which includes:
                  <ul style={{ marginLeft: 25 }}>
                    <li
                      style={{
                        listStyleType: "disc",
                        listStylePosition: "inside",
                      }}
                    >
                      sending service emails and communications as part of our
                      contractual agreement.
                    </li>
                    <li
                      style={{
                        listStyleType: "disc",
                        listStylePosition: "inside",
                      }}
                    >
                      notifying you about changes to our terms and conditions.
                    </li>
                    <li
                      style={{
                        listStyleType: "disc",
                        listStylePosition: "inside",
                      }}
                    >
                      notifying you of changes to our privacy policy.
                    </li>
                  </ul>
                </Tc>
                <Tc>
                  <ol>
                    <li style={{ listStyleType: "lower-alpha" }}>
                      Performance of a contract with you.
                    </li>
                    <li style={{ listStyleType: "lower-alpha" }}>
                      Legal obligation.
                    </li>
                  </ol>
                </Tc>
              </Tr>
              <Tr>
                <Tc>
                  To provide customer services such as:
                  <ul style={{ marginLeft: 25 }}>
                    <li
                      style={{
                        listStyleType: "disc",
                        listStylePosition: "inside",
                      }}
                    >
                      receiving and responding to issues and concerns.
                    </li>
                    <li
                      style={{
                        listStyleType: "disc",
                        listStylePosition: "inside",
                      }}
                    >
                      providing information to queries.
                    </li>
                    <li
                      style={{
                        listStyleType: "disc",
                        listStylePosition: "inside",
                      }}
                    >
                      receiving feedback and compliments.
                    </li>
                  </ul>
                </Tc>
                <Tc>Performance of a contract with you.</Tc>
              </Tr>
              <Tr>
                <Tc>
                  To enable direct marketing to:
                  <ul style={{ marginLeft: 25 }}>
                    <li
                      style={{
                        listStyleType: "disc",
                        listStylePosition: "inside",
                      }}
                    >
                      inform you about new products and services that may
                      interest you.
                    </li>
                    <li
                      style={{
                        listStyleType: "disc",
                        listStylePosition: "inside",
                      }}
                    >
                      inform you about promotions, offers, competitions or
                      events that may interest you.
                    </li>
                    <li
                      style={{
                        listStyleType: "disc",
                        listStylePosition: "inside",
                      }}
                    >
                      personalise and improve your experience.
                    </li>
                  </ul>
                </Tc>
                <Tc>
                  <ol>
                    <li style={{ listStyleType: "lower-alpha" }}>Consent</li>
                    <li style={{ listStyleType: "lower-alpha" }}>
                      Legitimate interests pursued by RateHogs to ensure we can
                      market and sell our products.
                    </li>
                  </ol>
                </Tc>
              </Tr>
              <Tr>
                <Tc>
                  To develop and improve our products and services through
                  insights about and from our customers.
                </Tc>
                <Tc>
                  Legitimate interests pursued by RateHogs to ensure our
                  products and services are valuable to our customers.
                </Tc>
              </Tr>
              <Tr>
                <Tc>
                  To ensure our platforms and websites remain safe and
                  functional and to prevent fraudulent activity (e.g.
                  troubleshooting, testing, system maintenance, monitoring and
                  reporting).
                </Tc>
                <Tc>
                  Legitimate interests pursued by RateHogs to keep our
                  infrastructure functional and safe.
                </Tc>
              </Tr>
              <Tr>
                <Tc>
                  To comply with legal obligations to which we are subjected.
                  Such as:
                  <ul>
                    <li
                      style={{
                        listStyleType: "disc",
                        listStylePosition: "inside",
                      }}
                    >
                      sharing information with regulatory and other official
                      bodies lawfully and proportionately.
                    </li>
                    <li
                      style={{
                        listStyleType: "disc",
                        listStylePosition: "inside",
                      }}
                    >
                      retaining information to meet statutory requirements.
                    </li>
                  </ul>
                </Tc>
                <Tc>Legal obligation</Tc>
              </Tr>
              <Tr>
                <Tc>
                  Establishing, exercising or defending our legal rights in
                  connection with litigation cases or regulatory investigations.
                </Tc>
                <Tc>Legitimate interests</Tc>
              </Tr>
            </div>
          </li>
          <li style={{ listStyleType: "number" }}>
            <h5>Third parties</h5>
            <p>
              In certain circumstances, RateHogs may need to share your personal
              data with third parties that provide products or services to us as
              part of a legitimate business need. These third parties may
              include:
            </p>
            <ul style={{ marginLeft: 25, marginBottom: 15 }}>
              <li style={{ listStyleType: "disc" }}>
                third-party suppliers (such as marketing and advertising
                companies).
              </li>
              <li style={{ listStyleType: "disc" }}>
                professional advisers and consultants such as external legal
                counsel.
              </li>
              <li style={{ listStyleType: "disc" }}>
                IT service providers (including enterprise resource planning and
                cloud-based software providers) RateHogs will carefully select
                third parties and enter into written agreements in line with
                applicable data protection laws.
              </li>
            </ul>
            <h5>Banking and Financial Institutions</h5>
            <p>
              We collect contact information such as your name, email address,
              and postal address. To share with the third-party companies who
              produce the product and services we are recommending to you. This
              allows them to offer you more relevant information and
              personalized experiences.
            </p>
            <p>
              RateHogs will carefully select third parties and enter into
              written agreements in line with applicable data protection laws.
            </p>
          </li>
          <li style={{ listStyleType: "number" }}>
            <h5>Where your data is stored</h5>
            <p>
              To ensure effective and efficiency of business, it is likely that
              data will be stored and processed in countries outside of Jamaica.
              Where there is an international transfer of personal data, we will
              only do so in accordance with applicable laws. We will ensure
              there is an adequate level of protection for personal data as it
              moves from Jamaica to a next country or territory. RateHogs will
              not transfer personal data to a country with an inadequate level
              of protection unless a transfer mechanism, recognised by law is in
              place.
            </p>
            <p>
              Personal data is currently stored in the Jamaica and in cloud
              databases located primarily in United States of America.
            </p>
            <p>
              For more information about the safeguards, we implement relating
              to the international transfer of personal data, please contact the
              Data Protection Team at (stephen.scale@solutechinnovation.com).
            </p>
          </li>
          <li style={{ listStyleType: "number" }}>
            <h5>Your Rights</h5>
            <p>
              Individuals have various rights under data protection laws and
              RateHogs will take appropriate steps to facilitate and fulfil such
              rights whether you are an applicant or employee. You have the
              right to:
            </p>
            <ul style={{ marginLeft: 25 }}>
              <li style={{ listStyleType: "disc" }}>
                request access to the personal data we hold about you and
                receive copies;
              </li>
              <li style={{ listStyleType: "disc" }}>
                request the correction or rectification of the personal data
                that we hold about you if that information is inaccurate or
                incomplete;
              </li>
              <li style={{ listStyleType: "disc" }}>
                stop or prevent the processing of your personal data, under
                certain circumstances;
              </li>
              <li style={{ listStyleType: "disc" }}>
                transmit the personal data you provided to us to another data
                controller in a structured, commonly used and machine-readable
                form;
              </li>
              <li style={{ listStyleType: "disc" }}>
                not to be subject to a decision based solely on automated
                decision-making, including profiling that will significantly
                affect you;
              </li>
              <li style={{ listStyleType: "disc" }}>
                provide consent for direct marketing communication or opt out
                where applicable;
              </li>
              <li style={{ listStyleType: "disc" }}>
                withdraw consent at any time; and
              </li>
              <li style={{ listStyleType: "disc" }}>
                lodge a complaint with the regulator about how we handle or
                process your personal data.
              </li>
            </ul>
            <p>
              These rights can be exercised by contacting our Data Protection
              Team at (dataprotection@solutechinnovation.com).
            </p>
          </li>
          <li style={{ listStyleType: "number" }}>
            <h5>Retention of personal data</h5>
            <p>
              RateHogs will retain personal data only as long as necessary to
              carry out the purposes for which it was obtained, including for
              satisfying any legal, accounting or contractual requirements and
              as detailed in this notice.
            </p>
            <p>
              Personal data will be held for as long as you are a customer or
              hold an account with us. RateHogs has internal retention policies
              and procedures intended to reflect the spirit of proportionality
              and will determine the appropriate retention period for the
              personal data we hold based on the following criteria:
            </p>
            <ul style={{ marginLeft: 25, marginBottom: 15 }}>
              <li style={{ listStyleType: "disc" }}>
                end of business relationship with us;
              </li>
              <li style={{ listStyleType: "disc" }}>
                the volume of the personal data;
              </li>
              <li style={{ listStyleType: "disc" }}>
                the nature and sensitivity of the personal data;
              </li>
              <li style={{ listStyleType: "disc" }}>
                the risk of harm from unauthorised use or disclosure;
              </li>
              <li style={{ listStyleType: "disc" }}>security reasons;</li>
              <li style={{ listStyleType: "disc" }}>
                applicable statutes of limitation;
              </li>
              <li style={{ listStyleType: "disc" }}>
                on-going or potential litigation or dispute; and
              </li>
              <li style={{ listStyleType: "disc" }}>
                regulatory or legal obligation to retain personal data.
              </li>
            </ul>
          </li>
          <li style={{ listStyleType: "number" }}>
            <h5>Security</h5>
            <p>
              RateHogs has put in place appropriate technical and organisational
              measures to prevent your personal data from being accidentally
              lost, used or accessed in an unauthorised way, altered or
              disclosed. Some of the measures we have implemented include:
            </p>
            <ul style={{ marginLeft: 25 }}>
              <li style={{ listStyleType: "disc" }}>
                firewall and network security frameworks;
              </li>
              <li style={{ listStyleType: "disc" }}>
                encryption of data at rest and in transit;
              </li>
              <li style={{ listStyleType: "disc" }}>
                robust access control management and auditing;
              </li>
              <li style={{ listStyleType: "disc" }}>security updates;</li>
              <li style={{ listStyleType: "disc" }}>
                anti-malware controls; and
              </li>
              <li style={{ listStyleType: "disc" }}>
                security awareness and staff training.
              </li>
            </ul>
            <p>
              We limit access to your personal data to those who need it to
              perform their roles, and all persons who have access are subject
              to a duty of confidentiality. We have implemented processes to
              address suspected and actual personal data breaches and will
              notify you and the applicable regulator where required.
            </p>
          </li>
          <li style={{ listStyleType: "number" }}>
            <h5>Contact and Complaints</h5>
            <p>
              RateHogs tries to meet the highest standards when collecting and
              using personal information. For this reason, we take any
              complaints we receive about this very seriously. We encourage
              individuals to bring it to our attention if they think that our
              collection or use of information is unfair, misleading, or
              inappropriate. We would also welcome any suggestions for improving
              our procedures.
            </p>
            <p>
              If you have any questions, comments or feedback regarding this
              notice or how we use your personal data, please feel free to
              contact the Data Protection Team at (support@ratehogs.com).
            </p>
            <p>
              If you are still dissatisfied with our handling of your complaint
              or our how we use your personal data, you have a right to complain
              to the Office of the Information Commissioner. You can report any
              concerns to them via email at{" "}
              <a
                href="mailto:info@oic.gov.jm"
              >
                info@oic.gov.jm
              </a>{" "}
              or call them on{" "}
              <a href="tel:876-920-4390">
                876-920-4390
              </a>
              .
            </p>
          </li>
          <li style={{ listStyleType: "number" }}>
            <h5>Revision of this notice </h5>
            <p>
              This notice will be periodically reviewed to reflect changes in
              processing activities and regulatory requirements. RateHogs will
              issue the updated privacy notice and notify that there is an
              updated version of the privacy notice.
            </p>
            <b>Policy version:</b> version 1<br />
            <b>Created</b>: November 2023 <br />
            <b>Next review date</b>: November 2024
          </li>
        </ol>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
