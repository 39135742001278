import React from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-regular-svg-icons";
import {
  faCarSide,
  faWallet,
  faHouse,
} from "@fortawesome/free-solid-svg-icons";



//Images
import Icon9 from "./../../assets/images/icons/icon9.svg";
import Icon10 from "./../../assets/images/icons/icon10.svg";
import Icon11 from "./../../assets/images/icons/icon11.svg";
import Icon12 from "./../../assets/images/icons/icon12.svg";
import baseUrl from "../../config"
/* import Icon13 from './../../assets/images/icons/icon13.svg';
import pic1 from './../../assets/images/about/pic1.jpg';
import support1 from './../../assets/images/icons/support1.png'; */


function OneStop() {
const cardData = [
  {
    image: Icon9,
    title: "Credit Cards",
    content:
      "Let us help you find the perfect credit card that fits your lifestyle and financial goals.",
    btnText: "Card Comparisons ",
    btnStatus: "active",
    btnLink: `${baseUrl}/credit-cards`,
    isReady: 1,
    icon: faCreditCard,
  },
  {
    image: Icon10,
    title: "Foreign Exchange",
    content:
      "Get the most out of your money by finding the best rates with our simple comparison tool.",
    btnText: "FX Comparisons",
    btnStatus: "active",
    btnLink: `${baseUrl}/fx-rates`,
    isReady: 1,
    icon: faWallet,
  },
  {
    image: Icon11,
    title: "Auto Loans",
    content:
      "Compare auto loans and save money before you get behind the wheel of your dream car.",
    btnText: "Auto Comparisons",
    btnStatus: "active",
    btnLink: `${baseUrl}/auto-loan`,
    isReady: 1,
    icon: faCarSide,
  },
  {
    image: Icon12,
    title: "Mortgages",
    content:
      "Find the best mortgage options, don't settle for a subpar mortgage, compare with us today.",
    btnText: "Mortgage Comparisons",
    btnStatus: "active",
    btnLink: `${baseUrl}/mortgage`,
    isReady: 1,
    icon: faHouse,
  },
];

  return (
    <>
      {cardData.map((item, ind) => (
        <div className="col-xl-4 col-md-6 m-b60" key={ind}>
          <div className="icon-bx-wraper style-3 text-center">
            <div className="icon-media">
              {/* <img src={item.image} alt="" /> */}
              <FontAwesomeIcon
                icon={item.icon}
                transform={{ rotate: 45 }}
                size="5x"
                style={{ color: "#ffffff" }}
              />
              {/* <FontAwesomeIcon icon={faCreditCard}  /> */}
              {/* <FontAwesomeIcon icon={faEnvelope} size="6x"/> */}
              {/* <FontAwesomeIcon icon="fa-solid fa-coffee" size="6x" /> */}
            </div>
            <div className="icon-content">
              <h4 className="title">{item.title}</h4>
              <p className="m-b0">{item.content}</p>
            </div>
            <br />
            <div>
              {item.isReady ? (
                <Button href={item.btnLink} variant="primary" size="sm">
                  {item.btnText}
                </Button>
              ) : (
                // <a href={item.btnLink }>{item.btnText}</a>
                // <Link to={{ pathname: item.btnLink }} target="_blank" className="btn btn-primary" disabled>{item.btnText}</Link>
                <Button variant="secondary" size="sm" disabled>
                  Coming Soon
                </Button>
              )}
            </div>
            <div></div>
          </div>
        </div>
      ))}
      {/* <div className="col-xl-4 col-md-6 m-b60">
                <div className="icon-bx-wraper style-4" style={{backgroundImage: "url("+ pic1 + ")"}}>
                    <div className="inner-content">
                        <div className="icon-media m-b30">
                            <img src={support1} alt="" />
                        </div>
                        <div className="icon-content">
                            <Link to={"/contact-us"} className="btn btn-primary">Call Us</Link>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
}
export default OneStop;
