import React from "react";
//import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faScaleBalanced,
  faFileShield,
} from "@fortawesome/free-solid-svg-icons";

//components
import OneStop from "./../components/Home/OneStop";

//images
import baner1 from "./../assets/images/home-banner/img5.png";
import baner2 from "./../assets/images/home-banner/img6.png";
import Shape1 from "./../assets/images/home-banner/shape7.png";
import Shape3 from "./../assets/images/home-banner/shape4.png";
import wallet from "./../assets/images/icons/wallet.svg";
import friend from "./../assets/images/icons/friend.svg";
import baseUrl from "../config";
//import coin4 from "./../assets/images/coins/coin4.png";

const trustBlog = [
  {
    image: wallet,
    icon: faScaleBalanced,
    title: "Impartiality",
    content:
      "We provide unbiased comparisons of financial products and services, without any preference for a particular company or institution. This means you can trust the information you're getting is objective and not influenced by any hidden agenda.",
  },
  {
    image: friend,
    icon: faFileShield,
    title: "Data accuracy",
    content:
      "We use accurate and up-to-date data when comparing financial products and services, so you can trust that the information you're getting is reliable.",
  },
];

function Home() {
  /* const nav = useNavigate();
  const formDetails = (e) => {
    e.preventDefault();
    nav("/contact-us");
  }; */
 
  const baseUrl = window.location.hostname !== "localhost" 
? "https://app.ratehogs.com"
: "http://localhost:3001";

  return (
    <>
      <div className="page-content">
        {/* <div className="dz-bnr-inr style-1 text-center">
					<div className="container">
						<div className="dz-bnr-inr-entry">
							
								<h1>Smarter Financial Decisions</h1>
								<p className="text text-primary " >Compare and find the best financial solutions for your needs.</p>
								<Link to={"/about-us"} className="btn space-lg btn-gradient btn-shadow btn-primary " >Compare Today</Link>
						</div>
					</div>                
					<img className="bg-shape1" src={Shape1} alt="" />
					<img className="bg-shape2" src={Shape1} alt="" />
					<img className="bg-shape3" src={Shape3} alt="" />
					<img className="bg-shape4" src={Shape3} alt="" />
				</div> */}
        <div className="main-bnr style-2">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12 text-center">
                {/* <h1 className="" >Your Globel OTC desk for <br />Cryptocurrencies</h1> */}
                <h1 className="">Smarter Financial Decisions</h1>
                <p className="text text-primary ">
                  Compare and find the best financial solutions for your needs.
                </p>
                {/* <Link to={"/about-us"} className="btn space-lg btn-gradient btn-shadow btn-primary " >Compare Today</Link> */}
                <a
                  target="_self"
                  href={`${baseUrl}/credit-cards`}
                  className="btn space-lg btn-gradient btn-shadow btn-primary"
                >
                  Compare Today
                </a>
                <ul className="image-before">
                  <li className="left-img">
                    <img src={baner1} alt="" />
                  </li>
                  <li className="right-img">
                    <img src={baner2} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <img className="bg-shape1" src={Shape1} alt="" />
          <img className="bg-shape2" src={Shape1} alt="" />
          <img className="bg-shape3" src={Shape3} alt="" />
          <img className="bg-shape4" src={Shape3} alt="" />
        </div>
        {/* <div className="clearfix bg-primary-light">
					<div className="container">
						<div className="currancy-wrapper">
							<div className="row justify-content-center">
								<BannerCard />
							</div>
						</div>
					</div>
				</div> */}

        <section className="content-inner bg-light icon-section section-wrapper2">
          <div className="container">
            <div className="section-head text-center">
              <h2 className="title">
                What
                <span className="text-primary"> Financial </span>
                products and services can you
                <span className="text-primary"> Compare? </span>
              </h2>
            </div>
            <div className="row sp60">
              <OneStop />
            </div>
          </div>
          <img className="bg-shape1" src={Shape1} alt="" />
        </section>
        <section className="clearfix section-wrapper1 bg-secondary-light">
          <div className="container">
            <div className="content-inner-1">
              <div className="section-head text-center">
                <h2 className="title">Why Trust Us?</h2>
                <p>
                  We strive to build trust with users by providing accurate,
                  impartial, and transparent information.
                </p>
              </div>
              <div className="row">
                {trustBlog.map((data, ind) => (
                  <div className="col-lg-6 m-b30" key={ind}>
                    <div className="icon-bx-wraper style-2">
                      <div className="icon-media">
                        {/* <img src={data.image} alt="" /> */}
                        <FontAwesomeIcon
                          icon={data.icon}
                          size="5x"
                          style={{ color: "#ffffff" }}
                        />
                      </div>
                      <div className="icon-content">
                        <h4 className="title">{data.title}</h4>
                        <p>{data.content}</p>
                        {/* <Link  className="btn btn-primary btn-gradient btn-shadow" to={"/about-us"}>Read More</Link> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* <div className="container">
						<div className="form-wrapper-box style-1 text-center">
							<div className="section-head " >
								<h4 className="title m-t0">How to Purchase from us ?</h4>
								<p>Fill out the below form and we will contact you via email & details</p>
							</div>
							<form  className="dz-form"  onSubmit={(e) => formDetails(e)}>
								<div className="form-wrapper">
									<div className="flex-1">
										<div className="row g-3">
											<div className="col-xl-3 col-md-6 " >
												<input name="dzName" type="text" required="" placeholder="Wallet Address" className="form-control" />
											</div>
											<div className="col-xl-3 col-md-6 " >
												<Dropdown className="select-drop">
													<Dropdown.Toggle as="div" className="i-false select-drop-toggle">
														<img src={selecttext[0]} alt=""/> {selecttext[1]} <i className="fa-sharp fa-solid fa-angle-down" />
													</Dropdown.Toggle>
													<Dropdown.Menu>
														<Dropdown.Item onClick={()=>setSelectText([coin4,"Bitcoin"])}><img src={coin4} alt=""/> Bitcoin</Dropdown.Item>
														<Dropdown.Item onClick={()=>setSelectText([coin3, "Ethereum"])}><img src={coin3} alt=""/> Ethereum</Dropdown.Item>
														<Dropdown.Item onClick={()=>setSelectText([coin1, "Tether"])}><img src={coin1} alt=""/> Tether</Dropdown.Item>
													</Dropdown.Menu>

												</Dropdown>
											</div>
											<div className="col-xl-3 col-md-6 " >
												<input name="dzName" type="text" required="" placeholder="How much worth in $?" className="form-control" />
											</div>
											<div className="col-xl-3 col-md-6 ">
												<input name="dzName" type="text" required="" placeholder="Email Address" className="form-control" />
											</div>
										</div>
									</div>
									<button type="submit" className="btn btn-lg btn-gradient btn-primary btn-shadow">Get Strated</button>
								</div>
							</form>
						</div>
					</div> */}
          <img className="bg-shape1" src={Shape1} alt="" />
        </section>
        {/* <section className="content-inner bg-white blog-wrapper">
					<img className="bg-shape1" src={Shape1} alt="" />

					<div className="container">
						<div className="row">
							<div className="col-xl-7 col-lg-12">
								<div className="section-head " >
									<h6 className="sub-title text-primary">FROM OUR BLOG</h6>
									<h2 className="title">Recent News &amp; Updates</h2>
								</div>
								<RecentNews />
							</div>
							<div className="col-xl-5 col-lg-12 m-b30 " >
								<div className="dz-card style-2" style={{backgroundImage: "url("+ bloglg +")"}}>
									<div className="dz-category">
										<ul className="dz-badge-list">
											<li><Link to={"#"} className="dz-badge">14 Fan 2022</Link></li>
										</ul>
									</div>
									<div className="dz-info">
										<h2 className="dz-title"><Link to={"/blog-details"} className="text-white">Directly support individuals Crypto</Link></h2>
										<div className="dz-meta">
											<ul>
												<li className="post-author">
													<Link to={"#"}>
														<img src={avatar3} alt=""  className="me-2"/>
														<span>By Noare</span>
													</Link>
												</li>
												<li className="post-date"><Link to={"#"}> 12 May 2022</Link></li>
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section> */}
      </div>
    </>
  );
}
export default Home;
